import { Col, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import logo from '../Assets/Images/fkp-logo.png';



function Content() {
    return(
        <>
        <div className='bg-white'>
          <Container className=' bg-white p-5'>
            <Row>
              <Col>
                <p className='text-center sub-title'>Support your local community theatre.</p>
              </Col>
            </Row>
          </Container>
        </div>
        <footer>
            <Container className='p-5'>
                <Row>
                    <Col className='col-md-3'>
                        <Image src={logo} className='img-fluid'></Image>
                        <p className='sub-title  text-white'>Founded by Sean Lippett-Fall & David Price</p>

                    </Col>
                    <Col className='col-md-2 offset-md-2'>
                    <p className='text-white'>Sean Lippett-FAll</p>
                    <p className='text-white'>FKP.Media</p>
                    </Col>

                    <Col className='text-white col-md-2 offset-md-2'>
                    <a href='http://twitter.com/fkp_media'> <i className='text-white fa-brands fa-x'></i></a>
                    <a href='https://www.facebook.com/people/A-Steady-Rain/100064165442327/'> <i className='text-white fa-brands fa-facebook'></i></a>
                    <a href='http://instagram.com/fkp.media'> <i className='text-white fa-brands fa-instagram'></i></a>
                    <a href='https://www.imdb.com/name/nm0266309/'> <i className='text-white fa-brands fa-imdb'></i></a>
                    </Col>

                </Row>
            </Container>
        </footer>
    </>
)};

export default Content;
