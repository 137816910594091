import Slider from "react-slick";
import "../../../Components/Slick/slick.css";
import "../../../Components/Slick/slick-theme.css";

import image1 from "./Gallery/a_steady_rain_gallery_1.jpg";
import image2 from "./Gallery/a_steady_rain_gallery_2.jpg";
import image3 from "./Gallery/a_steady_rain_gallery_3.jpg";
import image4 from "./Gallery/a_steady_rain_gallery_4.jpg";
import image5 from "./Gallery/a_steady_rain_gallery_5.jpg";
import image6 from "./Gallery/a_steady_rain_gallery_6.jpg";
import image7 from "./Gallery/a_steady_rain_gallery_7.jpg";
import image8 from "./Gallery/a_steady_rain_gallery_8.jpg";
import image9 from "./Gallery/a_steady_rain_gallery_9.jpg";
import image10 from "./Gallery/a_steady_rain_gallery_10.jpg";
import image11 from "./Gallery/a_steady_rain_gallery_11.jpg";
import image12 from "./Gallery/a_steady_rain_gallery_12.jpg";
import image13 from "./Gallery/a_steady_rain_gallery_13.jpg";
import image14 from "./Gallery/a_steady_rain_gallery_14.jpg";
import image15 from "./Gallery/a_steady_rain_gallery_15.jpg";
import image16 from "./Gallery/a_steady_rain_gallery_16.jpg";
import image17 from "./Gallery/a_steady_rain_gallery_17.jpg";
import image18 from "./Gallery/a_steady_rain_gallery_18.jpg";
import image19 from "./Gallery/a_steady_rain_gallery_19.jpg";
import image20 from "./Gallery/a_steady_rain_gallery_20.jpg";
import image21 from "./Gallery/a_steady_rain_gallery_21.jpg";

const imagesList = [
  {
    id: 1,
    src: image1,
    // alt: "Image 1",
  },
  {
    id: 2,
    src: image2,
  },
  {
    id: 3,
    src: image3,
  },
  {
    id: 4,
    src: image4,
  },
  {
    id: 5,
    src: image5,
  },
  {
    id: 6,
    src: image6,
    // alt: "Image 1",
  },
  {
    id: 7,
    src: image7,
  },
  {
    id: 8,
    src: image8,
  },
  {
    id: 9,
    src: image9,
  },
  {
    id: 10,
    src: image10,
  },
  {
    id: 11,
    src: image11,
    // alt: "Image 1",
  },
  {
    id: 12,
    src: image12,
  },
  {
    id: 13,
    src: image13,
  },
  {
    id: 14,
    src: image14,
  },
  {
    id: 15,
    src: image15,
  },
  {
    id: 16,
    src: image16,
    // alt: "Image 1",
  },
  {
    id: 17,
    src: image17,
  },
  {
    id: 18,
    src: image18,
  },
  {
    id: 19,
    src: image19,
  },
  {
    id: 20,
    src: image20,
  },
  {
    id: 21,
    src: image21,
  },
];

export default function SimpleSlider() {
  var settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: 1,

  };
  return (
    <Slider {...settings}>
      {imagesList.map((image) => (
        <div>
        <img key={image.id} src={image.src} alt="A Steady Rain" style={{height: "270px"}} />
        </div>
      ))}
    </Slider>
  );
}