
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import logo from '../Assets/Images/fkp-logo.png';



function NavBar() {
    return(
      <>
      <Row className=' '>
        <Navbar expand="lg" className=" navbar-outer p-0" id="navbar">
          <Container className="navbar-inner">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Container className="h-100" fluid >
              <Row>
  
                <Navbar.Collapse id="basic-navbar-nav" >
                  <Nav className=''>
                      <Navbar.Brand href="/">
                        <img
                          alt="Fkp Media"
                          src={ logo }
                          height="100"
                        />
                      </Navbar.Brand>
                  </Nav>
               
                    
                    <Nav className='justify-end w-100 '>
                    <NavDropdown title="Productions" id="basic-nav-dropdown" className='active'>
                        <NavDropdown.Item href="/productions/a-steady-rain">A Steady Rain</NavDropdown.Item>
                        <NavDropdown.Item href="/productions/killing-steve">Killing Steve</NavDropdown.Item>
                        <NavDropdown.Item href="/">Departure Services</NavDropdown.Item>
                        <NavDropdown.Item href="/">Job search assistance</NavDropdown.Item>
                      </NavDropdown>    
                      <Nav.Link href="/cast-and-crew" >Current Cast & Crew</Nav.Link>
                      <NavDropdown title="About" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/about/sean-lippett-fall">Sean Lippett-Fall</NavDropdown.Item>
                        <NavDropdown.Item href="/about/fkp-media">FKP.Media</NavDropdown.Item>
                      </NavDropdown>    
                      <Nav.Link href="/" >Media</Nav.Link>
                      
                      <Nav.Link href="/" >Contact Us</Nav.Link>
                      <Nav.Link href='http://twitter.com/fkp_media'> <i className='fa-brands fa-x'></i></Nav.Link>
                      <Nav.Link href='https://www.facebook.com/people/A-Steady-Rain/100064165442327/'> <i className='fa-brands fa-facebook'></i></Nav.Link>
                      <Nav.Link href='http://instagram.com/fkp.media'> <i className='fa-brands fa-instagram'></i></Nav.Link>
                      <Nav.Link href='https://www.imdb.com/name/nm0266309/'> <i className='fa-brands fa-imdb'></i></Nav.Link>

                    </Nav>
                  
                </Navbar.Collapse>

                
  
              </Row>
            </Container>
          </Container>
        </Navbar>
      </Row>
      </>
    );
  }
  
  
  export default NavBar;