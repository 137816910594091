import { Col, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import KillingSteveTitle from './killing-steve-1.jpg';

import Image1 from './killing-steve-2.jpg';
import Image2 from './killing-steve-3.jpg';

// import SimpleGallery from './SimpleGallery';




function Content() {
    return(
    <>
        <Row className='p-5 '>
            <Image src={KillingSteveTitle} className='img-fluid'></Image>
        </Row>
      
        <Container className='p-5 cast_and_crew'>
            <Row>
                <Col className='col-md-9'>
                    <h2>Lockdown, Countdown, Meltdown……..</h2>
                    <p>Zoe & Nikki have been in lockdown for three months - Nikki has started talking to the walls and losing her patience with Frank. Zoe’s been stalking celebrities on Instagram whilst binge-eating chocolate. </p>
                    <p>Their long-term friendship is at breaking point, and to top it all off, they must deal with Steve.</p>
                    <p>Two women, one room and a whole lot of chaos.</p>
                    <p>Starring Helen Pepper Smith (Playwright) & Sally C. Davis</p>
                    <p><b>Directed by Sean Lippett-Fall</b></p>
                </Col>

                <Col className='col-md-3'>
                    <Row>
                        <Col className='col-md-12'><Image src={Image1} className='img-fluid p-1'></Image></Col>
                        <Col className='col-md-12'><Image src={Image2} className='img-fluid p-1'></Image></Col>

                    </Row>
                </Col>
        </Row>
        </Container>
    </>
    );
}

export default Content;


/*

        <SimpleGallery
          galleryID="my-test-gallery"
          images={[
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg',
              width: 1875,
              height: 2500,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg',
              width: 1669,
              height: 2500,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
          ]}
        />
        */