import { Col, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import SeanLippetFall from './sean-lippett-fall.jpg';

function SeanLippett() {
    return(
        <>
            <Container className='p-5 cast_and_crew'>
                <Row>
                    <Col className='col-md-6'>
                        <h1>Sean Lippett-Fall</h1>
                        <hr />
                        <p>Sean Graduated from Arts Educational Method Acting School in 1995 and acquired Equity Registered Actor / Director Status Membership in 1996.</p>
                        <p>He loves to Direct & Act – Acting (Equity) Name Sean Fall. </p>
                        <p>Sean has Directed & Acted in numerous productions around the UK since leaving Drama School and he & his Casts have won multiple awards along the way.</p>
                        <p>Professional Theatre Credits' Include : </p>
                            <ul>
                                <li>Plough & The Stars - Tabard Theatre London - 1991</li>
                                <li>The Caucasian Chalk Circle </li> 
                                <li>A Little Night Music</li> 
                                <li>Blood Wedding </li>                    
                                <li>The Seagull</li>
                                <li>Pig In A Poke </li>
                                <li>Lark Rise To Candleford</li>
                                <li>The Balcony(Jean Genet)</li>
                                <li>The Destiny Of Me</li>
                                <li>The House Of Bernarda Alba </li>
                                <li>All Above In London Rep. Theatres 1991 To 1995</li>
                                <li>The Glass Menagerie – Gentleman Caller (Directed By Sam Mendes) Comedy Theatre West End 1996</li>
                            </ul>

                        <p>Direction / Production Credits:</p>
                            <ul>
                                <li>Contemporary Canterbury – BHTC 2011</li>
                                <li>Amongst Barbarians – BHTC 2012</li>
                                <li>Brief Encounter (NVT Award Winner) 2015</li>
                                <li>Amongst Barbarians (Arts Council Award Winner) 2017</li>
                                <li>A Guide To Second Date Sex – The Rialto (Brighton) 2018</li>
                                <li>A Steady Rain (Arts Council Award Winner) 2019</li>
                                <li>Killing Steve – Brighton Unitarian Halls 2020</li>
                            </ul>
                            <p>TV Credits:</p>
                            <ul>
                                <li>Bramwell (Young Doctor) 1994</li>
                                <li>The Bill (Colin) 1995</li>
                                <li>Invasion Earth Wycliffe 1996 (Police Diver)</li>
                                <li>(R.A.F Sentry) 1998</li>
                            </ul>
                    </Col>
                    <Col className='col-md-6'>
                        <Image src={SeanLippetFall} className='fluid-image'></Image>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SeanLippett;