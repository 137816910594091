
import { Col, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import final_sea from '../../Assets/Images/final_sea.png';
import cannery_row_real from '../../Assets/Images/cannery_row_real.jpg';

function Home() {
    return(
      <>
      <Container className='p-5'>
        <Row>
          <Col className='col-md-6 col-xs-12 offset-md-3'>
            <p>We are sad to announce that, due to unforeseen circumstances, the upcoming FKP.Media production of “Cannery Row” at The Cockpit theatre will not be taking place.</p>
            <p>We apologise to those who have purchased tickets and made arrangements to see the show.</p>
            <p>[Tickets will be refunded according to The Cockpit’s procedures; please check the website for further details.]</p>
            <center>
              <p>We hope to bring this amazing production to a stage again soon – please watch this space</p>
              <h1>CANNERY ROW</h1>
              <p className='sub-title'>Written by John Steinbeck</p>
              <p>Adapted by Sam Chittenden</p>
              <p>Directed by Sean Lippett-Fall</p>
              <Image src={final_sea} className='img-fluid'></Image>
            </center>
          </Col>
        </Row>
      </Container>

      <Row className='upwards-we-go'>

          <section className='wave bg-grey' style={{ height: '90px'}}></section>
      </Row>
      
      <div className='bg-grey  '>
          <Container className='bg-grey  pb-5'>
            <Row>
              <Col>
                <p className='sub-title'>From an idea by award winning director Sean Lippett-Fall.</p>
                <p>Previous Productions by Sean Lippett-Fall @ FKP.Media include the hugely successful 'A Steady Rain' by Keith Huff, which received critical acclaim & 3 Arts Council Awards.</p>
                <p>They also Produced 'Amongst Barbarians,' receiving an award for Best Actress, and 'Brief Encounter,' a short play for which they won The Director's Award.</p>
              </Col>
              <Col>
                <p>The first ever adaptation of Cannery Row granted full European rights by the Steinbeck Estate.</p>
                <p>A Steady Rain – 5 STAR REVIEWS</p>
                <p>★★★★★ Broadway Baby </p>
                <p>★★★★★ Latest Brighton</p>
                <p>★★★★★  Remote Goat</p>
              </Col>
            </Row>
          </Container>
        </div>

        <Row className='upwards-we-go'>
          <section className='wave bg-black' style={{ height: '90px'}}></section>
        </Row>
      
        {/* <div className='bg-black'>
          <Container className='bg-black text-white pb-5'>
            <Row>
              <Col>
                Images Will Go Here
              </Col>
            </Row>
          </Container>
        </div> */}

        <Row className='upwards-we-go'>
          <section className='wave bg-white' style={{ height: '90px'}}></section>
        </Row>
      
        <div className=''>
          <Container className=' p-5'>
            <Row>
              <Col>
              <p className='sub-title'>The story so far</p>
              <p>Around 4 years ago, I felt a dusty book in a second-hand shop in Brighton call to me, it turned out to be the novel Cannery Row by John Steinbeck.</p>

              <p>I have adored Steinbeck’s work since childhood. I loved the story and discovered that it had never been turned into a play. </p>

              <p>We presented Sam’s script to the Steinbeck Family, their lawyers and publishers and we were granted full rights to show the world premiere stage adaptation of Cannery Row.  </p>

              <p>My colleague Sam Chittenden, award-winning writer and director wrote the adaptation with my input and we have very high hopes for the show, as do the Steinbeck Estate.</p>

              <p>We hope the first Production at The Cock-Pit Theatre in London will give us the impetus to advance and extend our run.</p>
              </Col>
              <Col>
                <Image src={cannery_row_real} className='img-fluid'></Image>
              </Col>
            </Row>
          </Container>
        </div>


        <div className='bg-black'>
          <Container className=' bg-black text-white p-5'>
            <Row>
              <Col>
              <p className='text-center sub-title'>“It has always seemed strange to me...The things we admire in men, kindness and generosity, openness, honesty, understanding and feeling, are the concomitants of failure in our system. And those traits we detest, sharpness, greed, acquisitiveness, meanness, egotism and self-interest, are the traits of success. And while men admire the quality of the first they love the produce of the second.”
              <br /><br />― John Steinbeck , Cannery Row</p>
              </Col>
            </Row>
          </Container>
        </div>

      </>
    );
  }
  
  
  export default Home;