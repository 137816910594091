import { Routes, Route } from 'react-router-dom';


import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap/dist/react-bootstrap.js";

import "@fortawesome/fontawesome-free/css/all.css";
import './App.css';

import NavBar from './Components/NavBar';
import Footer from './Components/Footer';

import Home from './Pages/Home/Home';
import SteadyRain from './Pages/Productions/SteadyRain/SteadyRain';
import KillingSteve from './Pages/Productions/KillingSteve/KillingSteve';

import Cast from './Pages/Cast/Cast';

import SeanLippett from './Pages/SeanLippett/SeanLippett';


const App = () => {
  return (
     <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/productions/a-steady-rain" element={<SteadyRain />} />
        <Route path="/productions/killing-steve" element={<KillingSteve />} />
        <Route path="/cast-and-crew" element={<Cast />} />
        <Route path="/about/sean-lippett-fall" element={<SeanLippett />} />
    </Routes>
    <Footer />
     </>
  );
 };
export default App;