import { Col, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


import SteadyRainTitle from './a-steady-rain.jpg';
import SteadyRainShow from './steady-rain-show.jpg';
import SteadyRainBackstage from './steady-rain-backstage.jpg';


import Slider from './Slider';


function Content() {
    return(
    <>
        <Row className='pt-5 title-image g-0'>
            <Image src={SteadyRainTitle} className='img-fluid'></Image>
        </Row>

 
        <Container>
            <Row>
                <Col className='col-md-9'>
                    <p>Sean Luppett-Fall presents...</p>
                    <h1>A Steady Rain</h1>
                    <p>“A Steady Rain” is a gritty two-hander that explores various relationships and complex bonds formed grown on the tough streets of Chicago. Often amusing violent in equal measure, we follow Joey & Denny who have been best friends since School as they try to survive as Policemen on the unforgiving streets of Chicago.</p>
                    <p>With biting, often poetic repartee; a rainbow of coloured dialogue, we journey with the two friends &amp; a host of other characters on a fateful journey of love, life, family, pimps, drink, drugs and ultimately ask ourselves: what is true friendship?</p>
                    <p>Directed by Sean Lippett-Fall</p>
                    <p>Assistant Director: Pippa Jones</p>
                </Col>
                <Col className='col-md-3'>
                    <Col className='col-md-12'>
                        <Image src={SteadyRainShow} className='img-fluid'></Image>
                    </Col>
                    <Col className='col-md-12'>
                        <Image src={SteadyRainBackstage} className='img-fluid'></Image>
                    </Col>
                </Col>
                
            </Row>
    
        </Container>
        <Row className='pt-5 g-0'>
            <Slider />
        </Row>
    </>
    );
  }

  export default Content;