import { Col, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import sean_lippet from '../../Assets/Images/Headshots/sean-lippett-fall-head-shot.jpg';
import sam_chittenden from '../../Assets/Images/Headshots/sam-chittenden-head-shot.jpg';
import wioletta from '../../Assets/Images/Headshots/wioletta-lippett-headshot.jpg';
import isabella from '../../Assets/Images/Headshots/isabella-mccarthy-sommerville.jpg';
import lewis from '../../Assets/Images/Headshots/lewis-todhunter-headshot.jpg';
import tom from '../../Assets/Images/Headshots/tom-slater-hyndman-headshot.jpg';
import lorraine from '../../Assets/Images/Headshots/lorraine-yu-wai-yee.jpg';
import seth from '../../Assets/Images/Headshots/seth-morgan-headshot.jpg';
import phil from '../../Assets/Images/Headshots/phil-nair-brown.jpg';

import catie from '../../Assets/Images/Headshots/catie-ridewood.jpg';
import sam from '../../Assets/Images/Headshots/sam-nixon.jpg';
import mickey from '../../Assets/Images/Headshots/mickey-knighton.jpg';
import john from '../../Assets/Images/Headshots/john-black.jpg';
import katey from '../../Assets/Images/Headshots/katey-ann-fraser.jpg';





function Cast() {
    return(
    <>
        <Container className='p-5 cast_and_crew'>
            <Row>
                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={sean_lippet} className='img-fluid'></Image>
                    <p><span className='cast-title'>Sean Lippett-Fall</span>
                    <br />DIRECTOR / PRODUCER</p>
                    <p>Sean Graduated from Arts Educational Method Acting School in 1995 and acquired Equity Registered Actor / Director Status Membership in 1996.</p>
                    <p>He loves to Direct & Act – Acting (Equity) Name Sean Fall.</p>
                    <p>Sean has Directed & Acted in numerous productions around the UK since leaving Drama School and he & his Casts have won multiple awards along the way.</p>
                </Col>
                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={sam_chittenden} className='img-fluid'></Image>
                    <p><span className='cast-title'>Sam Chittenden</span>
                    <br />WRITER / ACTOR</p>

                    <p>Description goes hereOur adapter, Sam Chittenden, is an award-winning playwright based in Sussex.  Her play Clean won Best New Play at Brighton Fringe in 2019 and Outstanding Show in 2021.  She has been nominated for an Infallibles Award (Sary) and selected for Fringe Encore at SoHo Playhouse (Metamorphosis).  Sam has previously adapted Mary Shelley’s The Last Man and Kafka’s Metamorphosis into one woman shows, and written (Unquiet Slumbers) about a meeting between Emily Brontë and Cathy from Wuthering Heights.  Adapting Cannery Row was a lockdown project in 2020.</p>
                </Col>

                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={wioletta} className='img-fluid'></Image>
                    <p><span className='cast-title'>Wioletta Kamionka / Wioletta Lippett</span>
                    <br />PROMOTIONS MANAGER</p>

                    <p>Co-founder of : “Tourist Promotion Foundation MEA”</p>

                    <p>Worked with a creative Polish group co-creating an Independent Cultural Association “Bukownica Station”.</p>

                    <p>Professional Photographer since 2010 & Google 3D Tour Trusted Photographer.</p>

                    <p>Worked & Collaborated with numerous Businesses in London, Brighton and throughout the UK & Europe.</p>

                    <p>Promotions Manager : Having participated in many Theatrical Projects as both an Actor and Photographer.</p>
                </Col>

                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={isabella} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Isabella McCarthy Sommerville</span>
                    <br />ACTOR</p>
                    <p>Isabella is an award-winning actress and voiceover artist based in the South of England. She graduated from Royal Holloway, University of London in 2013, with a degree in Drama and Theatre Studies. Since then she has worked with some incredible directors and theatre companies - touring the UK and overseas. Isabella recently returned from New York where she made her off-broadway debut at Soho Playhouse, after being awarded the International Fringe Encore Series Award for her performance of Something Underground's Betsy: Wisdom of a Brighton Whore - a one woman show by Jonathan Brown. Other theatre credits include Anna in Anna Christie, Hilda in A Good Jew, Rachel in Inherit the Wind and Vickie in The Thrill of Love. Isabella is delighted to be joining the cast of Cannery Row at The Cockpit this Autumn.</p>
                    <p>Website: www.isabellamccarthysommerville.co.uk</p>
                    <p>Instagram: @isabellamccarthysommerville </p>
                </Col>

                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={lewis} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Lewis Todhunter</span>
                    <br />ACTOR</p>

                    <p>Lewis has appeared in several productions in 2022 including Mike Bartletts 'Cock' at the Brighton Fringe, The York Realis and The Herd. He recently played Bertie in The Kings Speech at this years Fringe and up next he is William Shakepeare in this summers Shakespeare in Love at Brighton Open Air Theatre.</p>

                </Col>

                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={tom} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Tom Slater-Hyndman</span>
                    <br />ASSISTANT DIRECTOR / ACTOR</p>

                    <p>Tom’s directorial credits include The Doppelganger, a short play, and The Seven Doors of Danny, a piece of musical theatre accompanied by full orchestra and male-voice choir.</p>

                    <p>He has acted in numerous productions, most recently appearing in It’s A Wonderful Life at Brighton’s New Venture Theatre and Paradise Rocks at Brighton Open Air Theatre.   </p>

                    <p>Tom is delighted to be assistant director of this world premiere of John Steinbeck’s Cannery Row.  </p>

                    <p>@tom_frog</p>
                </Col>


                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={lorraine} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Lorraine Yu Wai Yee (余蔚誼)</span>
                    <br />ACTOR</p>

                    <p>is a Brighton-based migrant actor and capoeirista. </p>

                    <p>Her recent works as an actor include “Bubbling” (BodiesforRent Theatre), “The Box We Tick”, </p>

                    <p>“Insomnia” (LegalAliens) and “The Happy Poor Guys” (Théâtre de la Feuille). </p>

                    <p>She is also passionate about working with different marginalised groups as a creative facilitator. </p>

                    <p>She has been exploring life and endless possibilities outside of Hong Kong, her beloved home, since 2020. </p>
                </Col>


                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={seth} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Seth Morgan</span>
                    <br />ACTOR</p>

                    <p>Seth trained at the Webber Douglas Academy. Recent Theatre credits include  Aonghas in, 'Awful People', Rory in 'Hard Times on Easy St.' (both at Latest Music Bar), Scrooge in 'A Christmas Carol' at St. Nicholas of Myra, Brighton, for Lantern Light Theatre, & various Script in hand open air performances for the first season of One Fell Swoop: Shakespeare Unlocked. Recent film work includes Sammy the Hacker in the ongoing online serial 'Demobbed' for Lumix/ Panasonic, & Phineas in the online serial 'Psychopaths Anonymous' by Django Watkins. Also a professional musician, he's lived in Brighton for nearly thirty years.</p>
                </Col>


                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={phil} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Phil Nair-Brown</span>
                    <br />ACTOR</p>

                    <p>Phil Nair-Brown graduated from Manchester Metropolitan University in 1998. Since 2022, Phil has won awards for leading roles in Blithe Spirit and Hedda Gabler. His other theatre credits include By Jeeves, Frankenstein, Macbeth, The Corn is Green, Disappeared and The 39 Steps. Phil has also been touring this year with an immersive show, The Wedding Party. </p>

                    <p>Phil is very excited to be cast in the world premiere of John Steinbeck’s Cannery Row at The Cockpit, London.</p>
                </Col>


                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={catie} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Catie Ridewood</span>
                    <br />ACTOR</p>

                    <p>Catie is a Brighton-based actor, and is thrilled to be able to work with Steinbeck’s characters in the world of Cannery Row.</p>

                    <p>She is currently playing Mary Shelley in “The Last”, a one-woman adaptation of Shelley’s novel “The Last Man” (DifferenT Theatre).</p>

                    <p>Other theatre includes:</p> 
                    <ul>
                        <li>Edith Gascoyne in “Blue Blood” (Brighton Fringe/Blue Devil Productions)</li>
                        <li>Isabella in “Measure 2 Measure” (DifferenT Theatre)</li> 
                        <li>Amanda in “Not Talking” (New Venture Theatre)</li>
                        <li>Aurora Bompas in "How He Lied to Her Husband" (New Venture Theatre)</li>
                        <li>Chris/Linda in “Between a Man and a Woman” (Bread & Roses Theatre)</li>
                        <li>Kina in “Unsanctioned” (New Venture Theatre)</li>
                        <li>Cindy in “Whose to Tell” (Baron’s Court Theatre)</li>
                    </ul>

                    <p>Catie graduated from the Academy of Creative Training: credits whilst training include Dol Common in “The Alchemist”.</p>
                </Col>


                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={sam} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Sam Nixon</span>
                    <br />ACTOR</p>

                    <p>Sam is a Sussex based actor, director, singer and theatre maker of more than 30 years, She is thrilled to be playing Dora and to be  working with this fantastic company on Steinbeck’s Cannery Row.</p>

                    <p>Recent theatre credits include:</p>
                    <ul>
                        <li>Polly Garter/Mrs Pugh in “Under Milk Wood” (Identity Theatre)</li>
                        <li>Company member “Sondheim Revisited” tour (GinPalace Priductions)</li>
                        <li>Nana  in “Taken” (Brighton little Theatre)</li>
                        <li>Julie Andrew’s in “Practically Perfect” (Gin Palace Productions)</li>
                        <li>Madam Arcati in “Blithe Spirit” (Southwick)</li>
                        <li>Prospera in “The Tempest” interactive family theatre, Brighton Aquarium  </li>
                    </ul>
                </Col>


                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={mickey} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Mickey Knighton</span>
                    <br />ACTOR</p>
                    <p>Mickey Knighton is a Brighton based actor hailing from Sheffield and of Irish descent. Fascinated by the craft of acting since youth and entertained by simply enjoying watching people being people, he went on to gain a BA in film and Sociology before going through his actor training at @actbrighton</p>
                    <p>Recent credits include Angelo in MEASURE 2 MEASURE at the Bridge House Theatre, Teddy Bompas in HOW HE LIED TO HER HUSBAND and Tom King in TURPIN. Mickey  also recently completed voiceover work as Paul on the second Doctor Who Adventures with the BBC. </p>
                </Col>


                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={john} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>John Black</span>
                    <br />ACTOR</p>

                    <p>John was born in Battersea and moved to Brighton in 2012. His boxer dog, Ruby, lives with his parents in Yorkshire.</p>
                    <p>Theatre lead roles include Captain John Hotson in The Geminus (Tristan Bates, London), Robin in UnChain Me (DreamThinkSpeak, Brighton Festival), Granillo in Rope, Bruce Flaherty in Blue/Orange, Orlando in As You Like It, Christopher Isherwood in I am a Camera and Romeo in Romeo & Juliet,</p>
                    <p>Award-winning short film includes: Harrybot 9000, Black Mirror: Transient, Tide of Ghosts, and mockumentary Seagull Man: The Brighton Superhero.</p>
                    <p>Television: National Geographic's Eyewitness: D-Day, bringing to life the true story of Major John Nigel Taylor, a British Paratrooper.</p>
                    <p>His first feature film Are We Monsters (Bubble Light Productions), as co-writer, co-director and in a leading role, premiered in Leicester Square as part of FrightFest 2021.</p>
                </Col>

                <Col className='col-md-4 col-xs-12 p-3'>
                    <Image src={katey} className='img-fluid'></Image>
                    
                    <p><span className='cast-title'>Katey Ann Fraser</span>
                    <br />ACTOR</p>

                    <p>Katey trained at KSA in South London specialising in Musical Theatre. Since graduating in 2014 she has been lucky enough to perform all around the world on cruise ships, in Pantomime, tours, fringe and has recently been working on some brand new Musical Theatre in London.</p>
                    <p>Recent credits include: Jessica in MIA: THE MUSICAL, SWING (covering all roles) in Dick Whittington, ROSE/FOX/BUSINESSMAN/LAMPLIGHTER in The Little Prince and various roles as part of the One Fell Swoop Project, including Ariel in THE TEMPEST, Dromio of Ephesus in THE COMEDY OF ERRORS and Casca in JULIUS CAESAR.</p>
                </Col>



            </Row>
        </Container>
    </>
    );
  }
  
  
  export default Cast;